<script setup>
import { useStore } from "vuex";
import router from "@/router";
const store = useStore();
import setActiveOrg from "@/scripts/dashboard_functs/setActiveOrg";
import Swal from "sweetalert2";
import Sidebar from "@/components/dashboard/sideBar.vue";
import Footer from "@/components/footer.vue";
import dash_header from "@/components/dashboard/dash_header.vue";
import { onBeforeMount } from "@vue/runtime-core";

onBeforeMount(() => {
    fetch("/api/account")
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (!result.ok) {
                Swal.fire({
                    icon: "error",
                    titleText: "Login to access dashboard",
                    confirmButtonColor: "#417dd8",
                    text: `
                
                `,
                    confirmButtonText: "Proceed to login",
                });
                router.push({ name: "login" });
            } else {
                store.state.username = result.username;
                store.state.orgs = result.orgs;
                store.state.email = result.email;
                store.state.isweb3 = result.isweb3;
                if (result.orgs.length > 0) {
                    return setActiveOrg(result.orgs[0]);
                } else {
                    if (typeof window.dataLayer == "object") {
                        window.dataLayer.push({ event: "web3-signup-start" });
                    }
                    router.push({ name: "setup-org" });
                }
            }
        })
        .catch((err) => console.log(err));

    if (typeof window !== "undefined") {
        store.state.network = JSON.parse(window.localStorage.getItem("network")) || "eth";
    }
});
</script>

<style lang="scss">
.container {
    .dashboard_container {
        padding: 60px 0px;
        justify-content: space-between;
        margin-bottom: 5em;
    }
}
</style>

<template>
    <div class="container">
        <dash_header />
        <main class="dashboard_container child_wrap flex">
            <Sidebar />
            <router-view />
        </main>
        <div class="container_black">
            <Footer />
        </div>
    </div>
</template>
