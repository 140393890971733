import getCookie from "@/scripts/utils/getCookie.js";
import router from "@/router";
import store from "../../store";

const logout = () => {
    store.state.loader = !store.state.loader;
    fetch("/api/logout", {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.ok) {
                router.push({ name: "login" });
                store.state.keys_item = [];
            }
            store.state.loader = !store.state.loader;
        });
};

export default logout;
