<script setup>
import { useStore } from "vuex";
import logout from "@/scripts/dashboard_functs/logout";
import Fade from "@/components/fade.vue";
import loadercomp from "@/components/loadercomp";
const store = useStore();
store.state.showView = false;

window.addEventListener("click", (e) => {
    if (!e.target.classList.contains("acct_modal") && !e.target.classList.contains("dropD")) {
        store.state.showView = false;
    }
});
</script>

<template>
    <div class="container wrap">
        <loadercomp v-if="$store.state.loader" />
        <header class="flex child_wrap flex_r">
            <router-link class="logo" to="/">
                <div class="img">
                    <img src="https://res.cloudinary.com/ferventdev/image/upload/v1642420872/openRelay.xyz/rivet_svg_p2983k.svg" alt="" />
                </div>
            </router-link>

            <div class="user_gravatar flex">
                <div class="grav_atar flex">
                    <img src="@/assets/gravatar.svg" alt="" />
                    <img @click="$store.dispatch('toggleView')" class="dropD" src="@/assets/drop-down.svg" alt="" />
                </div>
                <Fade>
                    <div v-if="$store.state.showView" class="acct_modal dropdown flex_col flex">
                        <router-link :to="{ name: 'account' }">
                            <div class="dropdown_item flex">
                                <i class="ri-user-line"></i>
                                &nbsp; &nbsp;
                                <p>Account</p>
                            </div>
                        </router-link>
                        <router-link class="cond_disp" :to="{ name: 'overview' }">
                            <div class="dropdown_item flex">
                                <i class="ri-door-lock-line"></i>
                                &nbsp; &nbsp;
                                <p>Keys</p>
                            </div>
                        </router-link>
                        <router-link class="cond_disp" :to="{ name: 'plan details' }">
                            <div class="dropdown_item flex">
                                <i class="ri-layout-bottom-2-line"></i>
                                &nbsp; &nbsp;
                                <p>Plan</p>
                            </div></router-link
                        >
                        <router-link class="cond_disp" :to="{ name: 'FAQ' }">
                            <div class="dropdown_item flex">
                                <i class="ri-question-answer-line"></i>
                                &nbsp;&nbsp;
                                <p>FAQ's</p>
                            </div>
                        </router-link>
                        <a href="https://rivet.cloud/docs">
                            <div class="dropdown_item flex">
                                <i class="ri-book-2-line"></i>
                                &nbsp; &nbsp;
                                <p>Documentation</p>
                            </div>
                        </a>
                        <div @click="logout" class="flex dropdown_item">
                            <i class="ri-logout-circle-line"></i>
                            &nbsp; &nbsp;
                            <p>Log Out</p>
                        </div>
                    </div>
                </Fade>
            </div>
        </header>
    </div>
</template>

<style lang="scss" scoped>
.wrap {
    background: #f5f5f5;
    header {
        padding: 15px 0px;
        .logo {
            width: 120px;
            img {
                width: 100%;
                @extend %nodrag;
            }
            @include media("<=phone-tab") {
                width: 95px;
            }
        }
        a {
            cursor: pointer;
            text-decoration: none;
            color: inherit;
        }
        .user_gravatar {
            position: relative;
            @extend %noselect;
            cursor: pointer;
            width: 10%;
            justify-content: flex-end;
            .grav_atar {
                img {
                    @extend %nodrag;
                    &:nth-child(1) {
                        width: 60px;
                        @include media("<=phone") {
                            width: 50px;
                        }
                    }
                }
            }
            .acct_modal {
                border-radius: 10px;
                padding: 2rem 1rem;
                gap: 1rem;
                top: 4.5em;
                width: 12rem;
                .cond_disp {
                    display: none;
                    @include media("<=desktop") {
                        display: block;
                    }
                }
                @include media("<=desktop") {
                    width: 17rem;
                }
                .dropdown_item {
                    cursor: pointer;
                    align-items: center;
                    i {
                        font-size: 1.3em;
                    }
                    .ri-logout-circle-line {
                        color: rgb(244, 106, 106) !important;
                    }
                }
                img {
                    width: 15%;
                }
                p {
                    font-size: 0.9em;
                }
            }
        }
    }
}
</style>
