<template>
    <nav>
        <ul class="flex">
            <li>
                <router-link :to="{ name: 'overview' }"
                    ><i class="ri-door-lock-line"></i>
                    &nbsp; &nbsp;
                    <p>Keys</p>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'plan details' }"
                    ><i class="ri-layout-bottom-2-line"></i>
                    &nbsp; &nbsp;
                    <p>Plan</p></router-link
                >
            </li>
            <li>
                <router-link :to="{ name: 'FAQ' }"
                    ><i class="ri-question-answer-line"></i>
                    &nbsp;&nbsp;
                    <p>FAQ's</p></router-link
                >
            </li>
        </ul>
    </nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
nav {
    @include media("<=desktop") {
        display: none;
    }
    width: 13%;
    ul {
        flex-direction: column;
        gap: 2.7rem 0;
        li {
            list-style-type: none;
            font-size: 1.1em;
            .router-link-active {
                i {
                    color: #417dd7;
                }
            }
            a {
                text-decoration: none;
                color: inherit;
                display: flex;
                align-items: center;
            }
            i {
                font-size: 1.3em;
            }
        }
    }
}
</style>
